input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.pointer {
  cursor: pointer;
}

.fs-20 {
  font-size: 20px;
}

.br-50 {
  border-radius: 50px;
}

/* Add this in your CSS file, e.g., App.css */

.vertical-timeline-element-icon::before {
  content: "";
  position: absolute;
  width: 4px;
  /* Adjust the width of the line */
  height: calc(100% + 130px);
  /* Adjust height to connect the logos */
  background: skyblue;
  /* Change the color of the line */
  left: 50%;
  /* Adjust this value to fine-tune the position */
  transform: translateX(-50%);
  z-index: -1;
}

.vertical-timeline-element-icon {
  position: relative;
  /* Ensure the icon is positioned relative to allow the ::before element to be positioned absolutely */
}

.vertical-timeline-element:last-child .vertical-timeline-element-icon::before {
  content: "";
  position: absolute;
  width: 4px;
  /* Adjust the width of the line */
  height: calc(100% + 10px);
  background: skyblue;
  left: 50%;
  /* Adjust this value to fine-tune the position */
  transform: translateX(-50%);
  z-index: -1;
}

.rbc-agenda-date-cell{
  color: black;
}

.rbc-agenda-time-cell{
  color: black;
}

.rbc-agenda-event-cell{
  color: black;
}