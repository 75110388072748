.WrapperHoverSidebar {
  .test {
    margin-right: 1.5rem !important;
  }
}
.WrapperHoverSidebar:hover {
  .test {
    margin-right: 1rem !important;
  }
}
.HomeIcon {
  margin-right: 0rem !important;
}
#PaddingRemove {
  padding: 0px 0px !important;
}
